import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import theme from '@utils/theme';
import useGlobalStyles from './globalCss.styles';

type ThemeProviderProps = {
  children: React.ReactNode;
};

export default function ThemeProvider({ children }: ThemeProviderProps) {
  const CssGlobal = () => {
    useGlobalStyles();

    return null;
  };
  return (
    <MuiThemeProvider theme={theme}>
      <CssGlobal />
      {children}
    </MuiThemeProvider>
  );
}
