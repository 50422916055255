import { MTFButton } from '@common/components/Button';
import { BodyText, Title } from '@common/components/Typography';
import { Modal } from '@mui/material';
import { Onfido } from 'onfido-sdk-ui';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { CUSTOM_UI_ONFIDO, token_onfido } from 'src/constant/onfido.config';
import { useInitOnfido } from 'src/hook/onfido.hook';

export const MedicalCheckPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const triggerMedicalDoc = useCallback(async () => {
    const onComplete = (data: any) => {
      setTimeout(() => {
        navigate('/trip-sheet');
        setOpen(false);
      }, 3000);
    };

    const language = {
      locale: 'en_US',
      phrases: {
        'document.upload.title_generic_document_front': `Submit your medical documentation`,
        'document.upload.title_generic_document_back': `Submit your medical documentation`,
        'document.upload.subtitle_upload': `Take a photo with your phone`,
      },
    };

    if (typeof window !== 'undefined') {
      setOpen(true);
      Onfido.init({
        token: token_onfido,
        containerId: 'onfido-mount',
        onComplete: onComplete,
        onError: err => {
          console.log(err);
        },
        customUI: CUSTOM_UI_ONFIDO,
        steps: [
          {
            type: 'document',
            options: {
              hideCountrySelection: true,
              genericDocumentTypes: [
                {
                  id: 'my_single_side_document',
                  title: 'My single side document',
                  subTitle: 'Details about my document',
                  country: 'ALB',
                  pages: 1,
                },
              ],
              documentTypes: {
                driving_licence: false,
                national_identity_card: false,
                residence_permit: false,
                passport: false,
                generic_document: true,
              },
            },
          },
          'complete',
        ],
        language: language,
      });
    }
  }, []);

  return (
    <>
      <Title sx={{ mt: 4, mb: 2 }}>Medical Documentation</Title>
      <BodyText sx={{ mb: 4 }}>
        To ensure you meet the latest medical requirements, please upload a copy
        of your valid pilot medical certificate.
      </BodyText>

      <MTFButton variant="contained" onClick={triggerMedicalDoc}>
        Start Verification
      </MTFButton>
      <Modal open={open}>
        <div id="onfido-mount"></div>
      </Modal>
    </>
  );
};
