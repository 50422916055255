import {
  TripSheet,
  WelcomePage,
  IdentityCheckPage,
  IdentityCheckConfirmPage,
} from './modules';
import { createBrowserRouter } from 'react-router-dom';
import { UserLayout } from '@common/layouts';
import { PilotLicenseCheckPage } from './modules/pages/pilot-license-check';
import PilotLicenseConfirmPage from './modules/pages/pilot-license-confirm';
import { MedicalCheckPage } from './modules/pages/medical-check';

const router = createBrowserRouter([
  {
    path: '/',
    element: <UserLayout />,
    children: [
      {
        path: '/',
        element: <WelcomePage />,
      },
      {
        path: '/welcome',
        element: <WelcomePage />,
      },
      {
        path: '/identity-check',
        element: <IdentityCheckPage />,
      },
      {
        path: '/identity-check-confirm',
        element: <IdentityCheckConfirmPage />,
      },
      {
        path: '/pilot-licence',
        element: <PilotLicenseCheckPage />,
      },
      {
        path: '/pilot-licence-confirm',
        element: <PilotLicenseConfirmPage />,
      },
      {
        path: '/medical-check',
        element: <MedicalCheckPage />,
      },
      {
        path: '/trip-sheet',
        element: <TripSheet />,
      },
    ],
  },
]);

export default router;
