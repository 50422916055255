import NavBar from '@common/components/NavBar';
import { Outlet, useLocation } from 'react-router-dom';
import { Main, Header, Divider } from './styled';

export default function UserLayout() {
  const location = useLocation();
  const isWelcomePage = ['/', 'welcome'].includes(location.pathname);

  return (
    <>
      <NavBar isWelcomePage={isWelcomePage} />
      <Main isWelcomePage={isWelcomePage}>
        <Header>
          <img src="/assets/text_logo.svg" alt="" />
          <Divider />
          <img src="/assets/logo_embraer.svg" alt="" />
        </Header>
        <Outlet />
      </Main>
    </>
  );
}
