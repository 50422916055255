import * as React from 'react';
import { ReactNode } from 'react';
import { ErrorPage } from '../error-page/errorPage';

export interface ErrorBoundaryProps {
  /** Should the developer exception message be displayed on the error page. Defaults to `true` when `NODE_ENV` is `development`. */
  showDeveloperExceptionMessage?: boolean;
  /** The component tree that the error boundary watches. */
  children: ReactNode;
}

export interface ErrorBoundaryState {
  hasError: boolean;
  message: string;
}

const defaultState: ErrorBoundaryState = {
  hasError: false,
  message: '',
};

/**
 * Catches any rendering errors in the component tree it wraps
 * and displays an error page.
 */
export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = defaultState;
  }

  static getDerivedStateFromError(error: Error): Partial<ErrorBoundaryState> {
    return { hasError: true, message: error.message };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    if (process.env.NODE_ENV === 'development') return;

    const message = `${JSON.stringify(error)} - ${JSON.stringify(
      errorInfo.componentStack,
    )}`;

    console.error(message);
  }

  render() {
    if (this.props.showDeveloperExceptionMessage && this.state.hasError) {
      return (
        <ErrorPage
          message={`Developer Exception Message: ${this.state.message}`}
        />
      );
    }

    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}
