import { styled } from '@mui/material';

export const Title = styled('div', {
  name: 'Title',
})<{ top?: number; bot?: number }>(({ theme }) => ({
  fontSize: theme.typography.h1.fontSize,
  fontWeight: theme.typography.h1.fontWeight,
  lineHeight: theme.typography.h1.lineHeight,
  color: '#00223D',
}));

export const SubTitle = styled('div', {
  name: 'SubTitle',
})<{ top?: number; bot?: number }>(({ theme }) => ({
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.h2.fontWeight,
  lineHeight: theme.typography.h2.lineHeight,
  color: '#29292B',
}));

export const BodyText = styled('div', {
  name: 'BodyText',
})<{ top?: number; bot?: number }>(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.body1.fontWeight,
  lineHeight: theme.typography.body1.lineHeight,
  fontFamily: 'Open Sans !important',
  color: '#585C61',
}));

export const BodyText2 = styled('div', {
  name: 'BodyText',
})<{ top?: number; bot?: number }>(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: '500',
  lineHeight: theme.typography.body1.lineHeight,
  fontFamily: 'Open Sans !important',
}));

export const BodyText3 = styled('div', {
  name: 'BodyText',
})<{ top?: number; bot?: number }>(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: '400',
  lineHeight: theme.typography.body1.lineHeight,
  fontFamily: 'Open Sans !important',
  color: '#29292B',
}));

export const Subtitle1 = styled('div', {
  name: 'Subtitle1',
})<{ top?: number; bot?: number }>(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: theme.typography.subtitle1.fontWeight,
  lineHeight: theme.typography.subtitle1.lineHeight,
  fontFamily: theme.typography.subtitle1.fontFamily,
  color: theme.palette.neutral.dark1,
}));

export const Subtitle2 = styled('div', {
  name: 'Subtitle2',
})<{ top?: number; bot?: number }>(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.subtitle2.fontWeight,
  lineHeight: theme.typography.subtitle2.lineHeight,
  fontFamily: theme.typography.subtitle2.fontFamily,
  color: theme.palette.neutral.dark3,
}));

export const Body2 = styled('div', {
  name: 'Body2',
})<{ top?: number; bot?: number }>(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  fontWeight: theme.typography.body2.fontWeight,
  lineHeight: theme.typography.body2.lineHeight,
  fontFamily: theme.typography.body2.fontFamily,
  letterSpacing: theme.typography.body2.letterSpacing,
  color: theme.palette.neutral.dark3,
}));
