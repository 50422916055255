import { Onfido } from 'onfido-sdk-ui';
import { CUSTOM_UI_ONFIDO } from 'src/constant/onfido.config';

export const useInitOnfido = () => {
  const triggerOpenOnfido = async ({
    onComplete,
    setOpen,
    steps,
    language = {},
  }: any) => {
    if (typeof window !== 'undefined') {
      setOpen(true);
      Onfido.init({
        token:
          'eyJhbGciOiJFUzUxMiJ9.eyJleHAiOjE3MTk0MDM4NTIsInBheWxvYWQiOnsiYXBwIjoiODYwMjc0MTEtMzhmMy00ZDQ5LWFkOWMtNDJhOWVlNjczNTkwIiwiY2xpZW50X3V1aWQiOiJjZTY5ZjE4Zi04MDA2LTQyM2QtYWZkYS1mY2FmZjBkZTIxNTQiLCJpc19zYW5kYm94IjpmYWxzZSwiaXNfc2VsZl9zZXJ2aWNlX3RyaWFsIjpmYWxzZSwiaXNfdHJpYWwiOmZhbHNlLCJyZWYiOiIqOi8vKi8qIn0sInV1aWQiOiJwbGF0Zm9ybV9zdGF0aWNfYXBpX3Rva2VuX3V1aWQiLCJlbnRlcnByaXNlX2ZlYXR1cmVzIjp7ImNvYnJhbmQiOnRydWUsImxvZ29Db2JyYW5kIjp0cnVlLCJoaWRlT25maWRvTG9nbyI6dHJ1ZSwidmFsaWRDcm9zc0RldmljZVVybHMiOlsiaHR0cHM6Ly9sb2NhbGhvc3Q6ODg4OC8iXSwidXNlQ3VzdG9taXplZEFwaVJlcXVlc3RzIjp0cnVlLCJkaXNhYmxlTW9iaWxlU2RrQW5hbHl0aWNzIjp0cnVlfSwidXJscyI6eyJkZXRlY3RfZG9jdW1lbnRfdXJsIjoiaHR0cHM6Ly9zZGsub25maWRvLmNvbSIsInN5bmNfdXJsIjoiaHR0cHM6Ly9zeW5jLm9uZmlkby5jb20iLCJob3N0ZWRfc2RrX3VybCI6Imh0dHBzOi8vaWQub25maWRvLmNvbSIsImF1dGhfdXJsIjoiaHR0cHM6Ly9hcGkub25maWRvLmNvbSIsIm9uZmlkb19hcGlfdXJsIjoiaHR0cHM6Ly9hcGkub25maWRvLmNvbSIsInRlbGVwaG9ueV91cmwiOiJodHRwczovL2FwaS5vbmZpZG8uY29tIn19.MIGIAkIBOBWOpAROWeQhPfaoW9rf9l_h-IAJOOFNTXvI4PNfltshuSwKDVBVVl_p5LK_awtk98y_bG6KUTWqobBWtMqmLtMCQgGMoeMiCV5u1cYWbyXIUcdfwA3OoXnChHR9GohBiWAe96RrYNJm-KoPpUNOhedeCE67hEoBQLYDwSWNsPpACL39XQ',
        containerId: 'onfido-mount',
        onComplete: onComplete,
        onError: err => {
          console.log(err);
        },
        customUI: CUSTOM_UI_ONFIDO,
        steps: steps,
        language: language ? language : undefined,
      });
    }
  };

  return { triggerOpenOnfido };
};
