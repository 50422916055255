import { styled } from '@mui/material';

export const Navbar = styled('div', {
  name: 'Navbar',
  shouldForwardProp: prop => prop !== 'isWelcomePage',
})<{ isWelcomePage?: boolean }>(({ theme, isWelcomePage }) => ({
  height: '100vh',
  width: '100%',
  position: 'fixed',
  zIndex: '1',
  top: '0',
  backgroundImage: isWelcomePage
    ? 'url(/assets/banner.svg)'
    : 'url(/assets/banner2.svg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  left: '0',
  overflowX: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  ...(!isWelcomePage
    ? {
        maxWidth: '400px',
        transition: 'all 0.3s ease-in-out',
      }
    : {
        maxWidth: '705px',
        transition: 'all 0.3s ease-in-out',
        backgroundPosition: 'center',
      }),
}));

export const StepWrapper = styled('div', {
  name: 'StepWrapper',
})<{ isWelcomePage?: boolean }>(({ theme, isWelcomePage }) => ({
  padding: '63px 0px 0px 75px',
  display: isWelcomePage ? 'none' : 'flex',
  flexDirection: 'column',
  gap: 24,
}));

export const Step = styled('div', {
  name: 'Step',
  shouldForwardProp: prop => prop !== 'active' && prop !== 'done',
})<{ active?: boolean; done?: boolean }>(({ theme, active, done }) => ({
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  gap: '12px',
  ...(!active && {
    opacity: 0.4,
  }),
  ...(done && {
    opacity: 1,
    '.stepName': {
      opacity: 0.4,
    },
  }),
}));
