import { BodyText, SubTitle } from '@common/components/Typography';
import * as constants from './errorPage.constants';
import { Box } from '@mui/material';
import { MTFButton } from '@common/components/Button';

export interface ErrorPageProps {
  /** An optional title to display instead of the default title. */
  title?: string;
  /** An optional message to display instead of the default message. */
  message?: string;
  /** The type of illustration to display. Defaults to 'bug'. */
}

export const ErrorPage = ({
  title = constants.DEFAULT_ERROR_PAGE_TITLE,
  message = constants.DEFAULT_ERROR_PAGE_MESSAGE,
}: ErrorPageProps) => {
  const retry = () => {
    location.reload();
  };

  return (
    <Box
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <SubTitle sx={{ mb: 6 }}>{title}</SubTitle>
      <BodyText sx={{ mb: 3 }}>{message}</BodyText>
      <MTFButton variant="contained" onClick={retry}>
        Reload
      </MTFButton>
    </Box>
  );
};
