import * as yup from 'yup';
const UNCOMPLETE = 'Complete this item to continue';
// TODO: add new properties
export const schema = yup.object().shape({
  firstname: yup
    .string()
    .matches(/^[a-zA-Z '-]*$/, 'First name cannot include special characters')
    .required('First name is required'),
  lastName: yup
    .string()
    .matches(/^[a-zA-Z '-]*$/, 'Last name cannot include special characters')
    .required('Last name is required'),
  dateOfBirth: yup
    .date()
    .max(new Date(), 'Date of birth cannot be in the future')
    .required('Date of birth is required'),
  licenseNumber: yup
    .number()
    .typeError('Please enter a valid number')
    .required('License number is required'),
  issuingAuthority: yup
    .string()
    .required('License issuing authority is required'),
  dateOfIssue: yup.date().required('Valid until is required'),
});
