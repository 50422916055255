import { Link } from 'react-router-dom';

type LinkProps = {
  to?: string;
  href?: string;
  children: React.ReactNode;
};

export default function DirectLink({ to, href, children }: LinkProps) {
  if (to) return <Link to={to}>{children}</Link>;
  return <a href={href}>{children}</a>;
}
