import { RouterProvider } from 'react-router-dom';

import router from './router';
import { ErrorBoundary } from '@common/components/Error/error-boundary';
import {
  ReactQueryClientProvider,
  createQueryClient,
} from './context/react-query-context';
import { ReactQueryDevtools } from 'react-query/devtools';

function App() {
  const queryClient = createQueryClient();

  return (
    <ErrorBoundary>
      <ReactQueryClientProvider queryClient={queryClient}>
        <RouterProvider router={router} />;
        <ReactQueryDevtools
          toggleButtonProps={{ style: { left: '5rem' } }}
          initialIsOpen={false}
        />
      </ReactQueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
