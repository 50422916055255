import { styled } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const StyledCheckedIcon = styled(CheckRoundedIcon, { name: 'CheckedIcon' })(
  ({ theme }) => ({
    color: 'green',
    borderRadius: '100%',
    width: 24,
    height: 24,
    fontSize: '50px !important',
    backgroundColor: 'white',
  }),
);

const UncheckedIcon = styled('div', {
  name: 'UncheckedIcon',
})(({ theme }) => ({
  width: 22,
  height: 22,
  fill: 'rgb(0,0,0,0)',
  border: '1px solid white',
  borderRadius: '100%',
}));

export const CheckIcon = ({
  checked = false,
  className,
}: {
  checked?: boolean;
  className?: string;
}) => {
  return checked ? (
    <StyledCheckedIcon className={className} />
  ) : (
    <UncheckedIcon className={className} />
  );
};
