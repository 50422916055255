import { useLocation } from 'react-router';
import { CheckIcon } from '../Icon';
import { Navbar, Step, StepWrapper } from './styled';
import { BodyText2 } from '../Typography';

const STEP_LIST = [
  {
    step: 1,
    stepName: 'Identity Check',
    path: ['/identity-check', '/identity-check-confirm'],
  },
  {
    step: 2,
    stepName: 'Flying Licence',
    path: ['/pilot-licence', 'pilot-licence-confirm'],
  },
  { step: 3, stepName: 'Medical Documentation', path: ['/medical-check'] },
  { step: 4, stepName: 'Trip Sheet', path: ['/trip-sheet'] },
];

export default function NavBar({ isWelcomePage }: { isWelcomePage: boolean }) {
  const location = useLocation();
  const step =
    STEP_LIST.find(item => item.path.includes(location.pathname))?.step || 1;

    console.log(location.pathname, step)
  return (
    <Navbar isWelcomePage={isWelcomePage}>
      <StepWrapper isWelcomePage={isWelcomePage}>
        {STEP_LIST.map((item, idx) => {
          console.log('item step', item.step);

          return (
            <Step active={step === item.step} done={step > item.step} key={idx}>
              <CheckIcon checked={step > item.step} />
              <BodyText2 className="stepName">{item.stepName}</BodyText2>
            </Step>
          );
        })}
      </StepWrapper>
    </Navbar>
  );
}
