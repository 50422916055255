import { MTFButton } from '@common/components/Button';
import { Body2, BodyText, BodyText3, Title } from '@common/components/Typography';
import { Box, Modal } from '@mui/material';
import { Onfido } from 'onfido-sdk-ui';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { CUSTOM_UI_ONFIDO, token_onfido } from 'src/constant/onfido.config';

const IdentityCheckPage = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const triggerWelcome = useCallback(async () => {
    const onComplete = (data: any) => {
      if (data) {
        const frontID = data.document_front.id;
        const backID = data.document_back.id;
        console.log(1234, data);
        setOpen(false);
        navigate('/identity-check-confirm');
      }
    };
    const steps = [
      {
        type: 'welcome',
        options: {
          title: 'Verify your identity',
          descriptions: [
            'Use your webcam or phone to photograph:',
            '1. your identity document',
            '2. your face',
          ],
          nextButton: 'Choose document',
        },
      },
      {
        type: 'document',
        options: {
          documentTypes: {
            driving_licence: true,
            national_identity_card: false,
            residence_permit: false,
            passport: false,
          },
        },
      },
    ];
    if (typeof window !== 'undefined') {
      setOpen(true);
      Onfido.init({
        token: token_onfido,
        containerId: 'onfido-mount',
        onComplete: onComplete,
        onError: err => {
          console.log(err);
        },
        customUI: CUSTOM_UI_ONFIDO,
        steps: steps,
      });
    }
  }, []);

  return (
    <>
      <Title sx={{ mt: 4, mb: 2 }}>Identity Check</Title>
      <BodyText sx={{ mb: 4 }}>
        First, we have to verify your identity. You will be required to upload
        your valid driver's license. A facial verification process will then be
        conducted to confirm your identity.
      </BodyText>
      <Body2 sx={{ mb: 2 }}>Required</Body2>
      <Box sx={{}}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src="/assets/identification-card.svg" alt="" />
          <BodyText3 sx={{ ml: 2 }}>Driving license</BodyText3>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 4 }}>
          <img src="/assets/scan-smiley.svg" alt="" />
          <BodyText3 sx={{ ml: 2 }}>Facial verification</BodyText3>
        </Box>
      </Box>

      <MTFButton variant="contained" onClick={triggerWelcome}>
        Start Verification
      </MTFButton>
      <Modal open={open}>
        <div id="onfido-mount"></div>
      </Modal>
    </>
  );
};

export default IdentityCheckPage;
