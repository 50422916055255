import { FC } from 'react';
import { useController } from 'react-hook-form';
import { ControlledTextInputProps } from './controlledTextField.type';
import { TextField } from '@mui/material';

export const ControlledTextField: FC<ControlledTextInputProps> = ({
  id,
  name,
  control,
  labelText,
  value,
  placeholder,
  required,
  shouldUnregister = false,
  disabled,
  variant,
  InputProps,
  ...props
}) => {
  const {
    field: { value: controlledValue, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    shouldUnregister,
  });

  return (
    <TextField
      value={value ? value.toString() : controlledValue}
      error={error ? true : false}
      fullWidth
      id={name ? name : id}
      label={labelText}
      variant={variant}
      disabled={disabled}
      placeholder={placeholder}
      required={required}
      helperText={<> {error?.message}</>}
      onChange={event => {
        onChange(event.target.value);
      }}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={InputProps}
      {...props}
    />
  );
};
