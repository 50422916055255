import { MTFButton } from '@common/components/Button';
import DirectLink from '@common/components/DirectLink';
import { BodyText, SubTitle, Title } from '@common/components/Typography';

const WelcomePage = () => {
  return (
    <>
      <Title sx={{ mt: 4, mb: 2 }}>Pilot Verification</Title>
      <SubTitle sx={{ mb: 6 }}>Welcome, Pilot!</SubTitle>
      <BodyText sx={{ mb: 3 }}>
        To ensure the security of our platform and compliance with regulations,
        all pilots are required to complete a Know Your Customer (KYC)
        verification process.
      </BodyText>
      <BodyText sx={{ mb: 6 }}>
        Successful completion will grant you access to the system, including
        your personalized Trip Sheet.
      </BodyText>
      <DirectLink to="/identity-check">
        <MTFButton variant="contained">Get Started</MTFButton>
      </DirectLink>
    </>
  );
};

export default WelcomePage;
