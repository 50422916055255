import {
  BodyText,
  Subtitle1,
  Subtitle2,
  Title,
} from '@common/components/Typography';
import { Box } from '@mui/material';

const TripSheet = () => {
  return (
    <>
      <Title sx={{ mt: 4, mb: 2 }}>Your demo flight awaits!</Title>
      <BodyText sx={{ mb: 4 }}>
        Review your flight details below and get ready to experience the future
        of flying.
      </BodyText>
      <img src="/assets/fly.svg" />
      <Box sx={{ mt: 2 }}>
        <Subtitle1>Pilot</Subtitle1>
        <Subtitle2>ANDREW JASON REYNOIL</Subtitle2>
      </Box>

      <Box
        sx={{
          border: '1px',
          borderStyle: 'dashed',
          borderColor: '#B7C2CD',
          mt: 2,
          mb: 2,
        }}
      ></Box>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ minWidth: '381px' }}>
          <Subtitle1>Departure Airport</Subtitle1>
          <Subtitle2>Nashville International Airport (BNA)</Subtitle2>
        </Box>
        <Box>
          <Subtitle1>Departure Time</Subtitle1>
          <Subtitle2>10:15 AM PST</Subtitle2>
        </Box>
      </Box>
      <Box
        sx={{
          border: '1px',
          borderStyle: 'dashed',
          borderColor: '#B7C2CD',
          mt: 2,
          mb: 2,
        }}
      ></Box>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ minWidth: '381px' }}>
          <Subtitle1>Arrival Airport</Subtitle1>
          <Subtitle2>Nashville International Airport (BNA)</Subtitle2>
        </Box>
        <Box>
          <Subtitle1>Arrival Time</Subtitle1>
          <Subtitle2>01:30 PM PST (estimated)</Subtitle2>
        </Box>
      </Box>
    </>
  );
};

export default TripSheet;
