export const CUSTOM_UI_ONFIDO = {
  googleFonts: ['Montserrat'],
  fontSizeTitle: '30px',
  fontSizeSubtitle: '16px',
  fontSizeBody: '16px',
  fontFamilyTitle: "'Montserrat',sans-serif",
  fontFamilyBody: "'Montserrat',sans-serif",
  fontFamilySubtitle: "'Montserrat',sans-serif",
  fontWeightTitle: '600',
  fontWeightSubtitle: '600',
  fontWeightBody: '400',
  colorBackgroundSurfaceModal: '#F7F9FA',
  colorBackgroundButtonPrimary: '#E63946',
  colorBorderButtonPrimary: '#E63946',
  colorBackgroundButtonPrimaryHover: '#AB2738',
  colorBorderButtonPrimaryHover: '#AB2738',
  colorBackgroundButtonPrimaryActive: '#E63946',
  colorBorderButtonPrimaryActive: '#E63946',
  colorContentButtonPrimaryText: '#FDEDEE',
  colorBorderSurfaceModal: '#A2A8B3',
  borderRadiusSurfaceModal: '5px',
  colorContentTitle: '#1E1E24',
  colorContentSubtitle: '#1E1E24',
  colorContentBody: '#1E1E24',
  colorInputOutline: 'none',
  colorBorderDocTypeButtonHover: 'none',
  colorBackgroundDocTypeButtonHover: 'none',
  colorBackgroundButtonIconHover: 'none',
  colorBorderDocTypeButton: '#636670',
  colorBorderDocTypeButtonActive: '#E63946',
  colorBackgroundButtonIconActive: 'none',
  colorContentLinkTextHover: '#1E1E24',
  colorBorderLinkUnderline: '#E63946',
  colorBackgroundLinkHover: 'none',
  colorBackgroundLinkActive: 'E63946',
  colorBackgroundDocTypeButtonActive: 'none',
  colorIcon: '#2B2D33',
  colorContentButtonTertiaryText: '#1E1E24',
  colorContentButtonSecondaryText: '#0E5078',
  colorBackgroundButtonSecondary: '#FFFFFF',
  colorBorderButtonSecondary: '#B7C2CD',
  colorBorderButtonSecondaryActive: 'none',
  colorBorderButtonSecondaryHover: 'none',
  colorContentAction: 'none',
  colorBackgroundAction: 'red',
};

export const token_onfido =
  'eyJhbGciOiJFUzUxMiJ9.eyJleHAiOjE3MTk0MDM4NTIsInBheWxvYWQiOnsiYXBwIjoiODYwMjc0MTEtMzhmMy00ZDQ5LWFkOWMtNDJhOWVlNjczNTkwIiwiY2xpZW50X3V1aWQiOiJjZTY5ZjE4Zi04MDA2LTQyM2QtYWZkYS1mY2FmZjBkZTIxNTQiLCJpc19zYW5kYm94IjpmYWxzZSwiaXNfc2VsZl9zZXJ2aWNlX3RyaWFsIjpmYWxzZSwiaXNfdHJpYWwiOmZhbHNlLCJyZWYiOiIqOi8vKi8qIn0sInV1aWQiOiJwbGF0Zm9ybV9zdGF0aWNfYXBpX3Rva2VuX3V1aWQiLCJlbnRlcnByaXNlX2ZlYXR1cmVzIjp7ImNvYnJhbmQiOnRydWUsImxvZ29Db2JyYW5kIjp0cnVlLCJoaWRlT25maWRvTG9nbyI6dHJ1ZSwidmFsaWRDcm9zc0RldmljZVVybHMiOlsiaHR0cHM6Ly9sb2NhbGhvc3Q6ODg4OC8iXSwidXNlQ3VzdG9taXplZEFwaVJlcXVlc3RzIjp0cnVlLCJkaXNhYmxlTW9iaWxlU2RrQW5hbHl0aWNzIjp0cnVlfSwidXJscyI6eyJkZXRlY3RfZG9jdW1lbnRfdXJsIjoiaHR0cHM6Ly9zZGsub25maWRvLmNvbSIsInN5bmNfdXJsIjoiaHR0cHM6Ly9zeW5jLm9uZmlkby5jb20iLCJob3N0ZWRfc2RrX3VybCI6Imh0dHBzOi8vaWQub25maWRvLmNvbSIsImF1dGhfdXJsIjoiaHR0cHM6Ly9hcGkub25maWRvLmNvbSIsIm9uZmlkb19hcGlfdXJsIjoiaHR0cHM6Ly9hcGkub25maWRvLmNvbSIsInRlbGVwaG9ueV91cmwiOiJodHRwczovL2FwaS5vbmZpZG8uY29tIn19.MIGIAkIBOBWOpAROWeQhPfaoW9rf9l_h-IAJOOFNTXvI4PNfltshuSwKDVBVVl_p5LK_awtk98y_bG6KUTWqobBWtMqmLtMCQgGMoeMiCV5u1cYWbyXIUcdfwA3OoXnChHR9GohBiWAe96RrYNJm-KoPpUNOhedeCE67hEoBQLYDwSWNsPpACL39XQ';
