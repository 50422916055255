import { MTFButton } from '@common/components/Button';
import { BodyText, BodyText3, Title } from '@common/components/Typography';
import { Modal } from '@mui/material';
import { Onfido } from 'onfido-sdk-ui';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { CUSTOM_UI_ONFIDO, token_onfido } from 'src/constant/onfido.config';
import { useInitOnfido } from 'src/hook/onfido.hook';

export const PilotLicenseCheckPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { triggerOpenOnfido } = useInitOnfido();

  const triggerPilotLicense = useCallback(async () => {
    const onComplete = (data: any) => {
      console.log({ data });
      setOpen(false);
      navigate('/pilot-licence-confirm');
    };

    const language = {
      locale: 'en_US',

      phrases: {
        'document.upload.title_generic_document_front': `Submit the front of pilot's license`,
        'document.upload.title_generic_document_back': `Submit the back of pilot's license`,
        'document.upload.subtitle_upload': `Take a photo with your phone`,
      },
    };
    const steps = [
      {
        type: 'document',
        options: {
          hideCountrySelection: true,
          genericDocumentTypes: [
            {
              id: 'pilot_license_front',
              title: 'Pilot license front',
              subTitle: 'Details about my document',
              country: 'ALB',
              pages: 1,
            },
            {
              id: 'pilot_license_back',
              title: 'Pilot license back',
              subTitle: 'Details about my document',
              country: 'ALB',
              pages: 2,
            },
          ],
          documentTypes: {
            driving_licence: false,
            national_identity_card: false,
            residence_permit: false,
            passport: false,
            generic_document: true,
          },
        },
      },
    ];
    if (typeof window !== 'undefined') {
      setOpen(true);
      Onfido.init({
        token: token_onfido,
        containerId: 'onfido-mount',
        onComplete: onComplete,
        onError: err => {
          console.log(err);
        },
        customUI: CUSTOM_UI_ONFIDO,
        steps: steps,
        language: language,
      });
    }
  }, []);

  return (
    <>
      <Title sx={{ mt: 4, mb: 2 }}>Pilot's License</Title>
      <BodyText sx={{ mb: 4 }}>
        Next, you will be required to upload separate high-resolution images of
        the front and back of your valid pilot's license.
      </BodyText>

      <MTFButton variant="contained" onClick={triggerPilotLicense}>
        Start Verification
      </MTFButton>
      <Modal open={open}>
        <div id="onfido-mount">
          <BodyText
            id="icon-close-onfido"
            sx={{ mb: 4, position: 'absolute', top: '10px', right: '10px' }}
          >
            Next
          </BodyText>
        </div>
      </Modal>
    </>
  );
};
