import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

/** props for react query provider */
export interface ReactQueryClientProviderProps {
  queryClient: QueryClient;
  children: React.ReactNode;
}

/** Create query client helpder */
export const createQueryClient = (): QueryClient => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        notifyOnChangeProps: 'tracked',
      },
      mutations: {
        onError: error => {
          console.log(error);
        },
      },
    },
  });
  return queryClient;
};

/** React query provider wrapper */
export const ReactQueryClientProvider = ({
  queryClient,
  children,
}: ReactQueryClientProviderProps) => {
  return (
    <QueryClientProvider client={queryClient} contextSharing>
      {children}
    </QueryClientProvider>
  );
};
