import { MTFButton } from '@common/components/Button';
import { ControlledTextField } from '@common/components/ControlledTextField/controlledTextField';
import { BodyText, Title } from '@common/components/Typography';
import { Modal, styled } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './pilotLicenseConfirm.schema';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useInitOnfido } from 'src/hook/onfido.hook';
import { Onfido } from 'onfido-sdk-ui';
import { CUSTOM_UI_ONFIDO, token_onfido } from 'src/constant/onfido.config';

const NameRow = styled('div', { name: 'NameRow' })(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns:
    'calc((100% - 48px) / 3) calc((100% - 48px) / 3) calc((100% - 48px) / 3)',
  gridGap: '24px',
}));

const FormWrapper = styled('div', { name: 'Row' })(({ theme }) => ({
  display: 'flex',
  gap: '24px',
  flexDirection: 'column',
  marginBottom: '32px',
}));

export const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'BTC',
    label: '฿',
  },
  {
    value: 'JPY',
    label: '¥',
  },
];
const PilotLicenseConfirmPage = () => {
  const { control, handleSubmit } = useForm({
    shouldFocusError: true,
    resolver: yupResolver(schema),
    defaultValues: {
      firstname: 'John',
      lastName: 'John',
    },
  });
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const onSubmit = useCallback(async () => {
    const onComplete = (data: any) => {
      if (data.face) {
        const selfieID = data.face.id;
        const variant = data.face.variant;
        console.log(1234, selfieID, variant);
      }
      setTimeout(() => {
        navigate('/medical-check');
      }, 3000);
    };
    if (typeof window !== 'undefined') {
      setOpen(true);
      Onfido.init({
        token: token_onfido,
        containerId: 'onfido-mount',
        onComplete: onComplete,
        onError: err => {
          console.log(err);
        },
        customUI: CUSTOM_UI_ONFIDO,
        steps: ['complete'],
      });
    }
  }, [setOpen]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate id="selfie">
      <Title sx={{ mt: 4, mb: 2 }}>Pilot's License</Title>
      <BodyText sx={{ mb: 3 }}>
        Please take some time to confirm the details below are correct. If you
        need to make any changes, you can do so now.
      </BodyText>
      <FormWrapper>
        <NameRow>
          <ControlledTextField
            required
            control={control}
            name="firstname"
            labelText="First name"
            variant="filled"
          />
          <ControlledTextField
            control={control}
            name="middleName"
            labelText="Middle name"
            variant="filled"
          />
          <ControlledTextField
            required
            control={control}
            name="lastName"
            labelText="Last name"
            variant="filled"
          />
        </NameRow>
        <ControlledTextField
          required
          control={control}
          name="dateOfBirth"
          labelText="Date of birth"
          variant="filled"
          type="date"
        />
        <ControlledTextField
          required
          control={control}
          name="issuingAuthority"
          labelText="License issuing authority"
          variant="filled"
        />

        <ControlledTextField
          type="number"
          required
          control={control}
          name="licenseNumber"
          labelText="Pilot licence number"
          variant="filled"
        />

        <ControlledTextField
          required
          control={control}
          name="dateOfIssue"
          labelText="Date of Issue"
          variant="filled"
          type="date"
        />
      </FormWrapper>

      <MTFButton variant="contained" type="submit" form="selfie">
        Confirm and Continue
      </MTFButton>
      <Modal open={open}>
        <div id="onfido-mount"></div>
      </Modal>
    </form>
  );
};

export default PilotLicenseConfirmPage;
